import { useState, useEffect } from "react";

export const useCountdown = (initialDuration = 120, interval = 1000) => {
    const [duration, setDuration] = useState(initialDuration);
    const isEnded = duration === 0;

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        return `${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
    };
    useEffect(() => {
        const intervalId = setInterval(() => {
            setDuration((prevDuration) => Math.max(prevDuration - 1, 0));
        }, interval);

        return () => {
            clearInterval(intervalId);
        };
    }, [duration, interval]);

    return { counter: formatTime(duration), isEnded };
};
