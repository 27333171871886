/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/order */
import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import Select, { components } from "react-select";

import { Box } from "@chakra-ui/react";
import { DropdownDownIcon } from "components/icons";
import {
  LOCAL_STORAGE_LANG_KEY,
  MOBILE_OPTIONS,
  OptionType,
  getCustomeStyles
} from "./data";

export const SelectLocale = () => {
  const { i18n } = useTranslation();
  const history = useHistory();

  const { pathname, search } = history.location;

  const getDefaultLang = () =>
    localStorage.getItem(LOCAL_STORAGE_LANG_KEY) ?? "az";

  const [selectedLocale, setSelectedLocale] = useState(getDefaultLang());

  useEffect(() => {
    const pathnameWithoutLocale = pathname.replace(/\/(en|ru)/, "");

    const pathnameNext = `${
      selectedLocale === "az" ? "" : `/${selectedLocale}`
    }${pathnameWithoutLocale === "/" ? "" : pathnameWithoutLocale}`;

    history.replace(`${pathnameNext}${search}`);

    i18n.changeLanguage(selectedLocale);

    localStorage.setItem(LOCAL_STORAGE_LANG_KEY, selectedLocale);
  }, [selectedLocale]);

  const handleChange = useCallback((item: OptionType) => {
    setSelectedLocale(item?.value ?? "az");
  }, []);

  return (
    <Select
      onChange={handleChange}
      placeholder=""
      defaultValue={{
        label: selectedLocale?.toUpperCase(),
        value: selectedLocale
      }}
      components={{
        DropdownIndicator: (props) => (
          <components.DropdownIndicator {...props}>
            <Box
              transitionProperty="all"
              transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
              transitionDuration="150ms"
              transform={`rotate(${
                props?.selectProps?.menuIsOpen ? "180" : 0
              }deg)`}
              justifyContent="center"
            >
              <DropdownDownIcon />
            </Box>
          </components.DropdownIndicator>
        )
      }}
      isSearchable={false}
      styles={getCustomeStyles()}
      options={MOBILE_OPTIONS}
    />
  );
};
