// atoms.js
import { atom } from "recoil";

import { IStepsInfo } from "pages/MSMELoan/models";

export const formValuesAtom = atom({
    key: "msmeLoan:formValues",
    default: {} as IStepsInfo
});

export default {
    "msmeLoan:formValuesAtom": formValuesAtom
};
