import { ReactNode } from "react";

import { BoxProps } from "@chakra-ui/react";

import TopBar from "components/header/TopBar";

interface IProps extends BoxProps {
  children?: ReactNode;
  isLanding: boolean;
}
const Header = ({ children, isLanding, ...rest }: IProps) => (
  <TopBar isLanding={isLanding} {...rest}>
    {children}
  </TopBar>
);

export default Header;
